import {isEmpty} from '@/commons/utils/functions';
import i18n from '@/lang/lang_config'

export const required = params => {

    return {required: true, message: i18n.tc('rules.field_mandatory', {field: params}), trigger: 'change'}

};

export const checkEquals = (params, callback) => {
    if (params.value !== params.compare_to) {
        callback(new Error(i18n.tc('rules.field_not_equal', {
            field: params.field,
            compare_field: params.compare_field
        })));
    } else {
        callback();
    }
};

export const type = params => {
    return {type: params.type, message: i18n.tc('rules.invalid_field', {field: params.field}), trigger: 'blur'}

};
export const range = params => {

    var mensage = i18n.tc('rules.invalid_range', {field: params.field});
    if (params.min != undefined) {
        mensage += i18n.tc('rules.invalid_range_higher', {field: params.min});
    }
    if (params.min != undefined && params.max != undefined) {
        mensage += i18n.tc('rules.invalid_range_and');
    }
    if (params.max != undefined) {
        mensage += i18n.tc('rules.invalid_range_lower', {field: params.max});
    }
    return {
        min: params.min,
        max: params.max,
        message: mensage,
        trigger: 'blur'
    }

};
export var checkDocuments = (rule, value, callback) => {
    if (!(value)) {
        return callback(new Error(i18n.tc('rules.should_accept_terms', {name: rule.field})));
    } else {
        return callback();
    }
};
export var checkEspacioBlanco = (rule, value, callback) => {
       if (isEmpty(value.trim())) {
                return callback(new Error(i18n.tc('rules.field_mandatory', {name: rule.field})));
            } else {
                return callback();
            }
    };
export const EspacioBlanco = () => {
    return {
        validator: checkEspacioBlanco, trigger: 'blur'
    }
};
export var checkOnlyLeter = (rule, value, callback) => {
    if (!(/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/.test(value))) {
        return callback(new Error(i18n.tc('rules.invalid_field', {name: rule.field})));
    } else {
        return callback();
    }
};
export const onlyLeter = () => {
    return {
        validator: checkOnlyLeter, trigger: 'blur'
    }
};

export const Cedula = () => {

    return {
        validator: checkCI, trigger: 'blur'
    }

};
export const Rut = () => {
    return {
        validator: checkRut, trigger: 'blur'
    }

};

export var checkConditions = (rule, value, callback) => {

    if (!(value)) {
        return callback(new Error(i18n.tc('rules.should_accept_terms', {name: rule.field})));
    } else {
        return callback();
    }
};

var checkRut = (rule, value, callback) => {
    let rut = value;
    let valid = true;
    if (isEmpty(value)) {
        return callback(new Error(i18n.tc('rules.document_number_mandatory')));
    }
    if (value.length !== 12) {
        valid = false;
    }
    if (!/^([0-9])*$/.test(rut)) {
        valid = false;
    }
    let dc = value.substr(11, 1);
    rut = value.substr(0, 11);
    let total = 0;
    let factor = 2;

    for (let i = 10; i >= 0; i--) {
        total += (factor * rut.substr(i, 1));
        factor = (factor === 9) ? 2 : ++factor;
    }
    let dv = 11 - (total % 11);

    if (dv === 11) {
        dv = 0;
    } else if (dv === 10) {
        dv = 1;
    }
    if (dv === dc) {
        valid = true;
    }
    if (!valid) {
        return callback(new Error(i18n.tc('rules.document_company_invalid_format')));
    } else {
        callback();
    }

};

var checkCI = (rule, value, callback) => {
    if (isEmpty(value)) {
        return callback(new Error(i18n.tc('rules.document_number_mandatory')));
    }
    const CI_MIN_LEN = 7;

    const CI_COEF = "2987634";
    let ci = value;
    let valid = false;
    if (ci.length >= CI_MIN_LEN) {
        let a = 0;
        if (ci.length === CI_MIN_LEN) {
            ci = "0" + ci;
        }
        for (var i = 0; i < ci.length - 1; i++) {
            a += (parseInt(CI_COEF[i]) * parseInt(ci[i])) % 10;
        }
        let digitoVerificador = 0;
        if (a % 10 !== 0) {
            digitoVerificador = 10 - a % 10;
        }
        valid = (digitoVerificador == ci[ci.length - 1]);
    }
    if (!valid) {
        return callback(new Error(i18n.tc('rules.document_applicant_invalid_format')));
    } else {
        callback();
    }
};

